import React from 'react';
import * as styles from './CustomForm.module.scss';
import classnames from 'classnames';
import { ArrowRight } from 'react-feather';
import { useState } from "react";
import { useIntl } from 'react-intl';

type intlObj = { id: string, defaultMessage: string }
type InputProps = { label?: intlObj; name: string; placeholder?: intlObj; type: string, pattern?: string, required: boolean };
type CustomForm = {
  className?: string;
  description?: string;
  title?: string;
  name: InputProps;
  phone: InputProps,
  email: InputProps,
  age: InputProps,
  centre: InputProps,
  submit?: { placeholder: intlObj };
  checkBox?: InputProps;
}
const CustomForm = ({
  className,
  title,
  description,
  name = {} as InputProps,
  phone = {} as InputProps,
  email = {} as InputProps,
  age = {} as InputProps,
  centre = {} as InputProps,
  checkBox = {} as InputProps,
  submit = {
    placeholder: { id: 'send', defaultMessage: 'Send' }
  },
}: CustomForm) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({
    User_name: "",
    User_phone: "",
    User_email: "",
    Age: "",
    Area: "",
    privacy: ""
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    let newErrors = {};
    const phonePattern = /^[235698]\d{7}$/;
    var emailpattern = /^[a-zA-Z0-9@\.\-\_]+$/;
    if (!formData.User_name.trim()) newErrors.User_name = "Name is required";
    if (!formData.User_phone.trim()) {
      newErrors.User_phone = "Phone number is required";
    } else if (!phonePattern.test(formData.User_phone)) {
      newErrors.User_phone = "Invalid phone number format";
    }
    if (!formData.User_email.trim()) {
      newErrors.User_email = "Email is required";
    } else if (!emailpattern.test(formData.User_email)) {
      newErrors.User_email = "Invalid email format";
    }
    if (formData.Age == 'Not Chosen' || !formData.Age) {
      newErrors.Age = "Please select your age";
    }
    if (formData.Area == 'Not Chosen' || !formData.Area) newErrors.Area = "Please select a centre";
    if (!formData.privacy) newErrors.privacy = "Please agree to the Privacy Policy of Wall Street English.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked === true ? "privacy" : "" : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    e.target.submit();
    console.log("Form Submitted:", formData);
    setFormData({ User_name: "", User_phone: "", User_email: "", Age: "", Area: "", privacy: '' });
  };

  return (
    <form onSubmit={handleSubmit} name="leadForm"  action="https://apps.wallstreet.edu.hk/action/formAction.jsp"
    method="POST">
      <input type="hidden" name="mediacategory" value="WSI Web Site" />
      <input type="hidden" name="supplier" value="WSI Web Site" />
      <input type="hidden" name="format" value="Website" />
      <input type="hidden" name="type" value="Enquiry" />
      <input type="hidden" name="mediaid" value="website-buddyprogram" />
      <input
        type="hidden"
        name="returnURL"
        value="https://apps.wallstreet.edu.hk/chi/site/thankyou_contact.jsp"
      />
      <table cellSpacing="5" cellPadding="5" style={{ width: "60%" }}>
        <tbody>
          <tr>
            <td>
              <label>{intl.formatMessage({ id: name.label?.id, defaultMessage: name.label?.defaultMessage })}</label>
            </td>
            <td>
              <div className={classnames(styles.requiredField, "is-secondary is-fullwidth mb-4")}>
                <input name="User_name" type="text" className="input" value={formData.User_name} onChange={handleChange} />
                {errors.User_name && <span style={{ color: "red" }}>{errors.User_name}</span>}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>{intl.formatMessage({ id: phone.label?.id, defaultMessage: phone.label?.defaultMessage })}</label>
            </td>
            <td>
              <div className={classnames(styles.requiredField, "is-secondary is-fullwidth mb-4")}>
                <input name="User_phone" type="text" className="input" value={formData.User_phone} onChange={handleChange} />
                {errors.User_phone && <span style={{ color: "red" }}>{errors.User_phone}</span>}
              </div>
            </td>
          </tr>
          <tr>
            <td><label>{intl.formatMessage({ id: email.label?.id, defaultMessage: email.label?.defaultMessage })}</label></td>
            <td>
              <div className={classnames(styles.requiredField, "is-secondary is-fullwidth mb-4")}>
                <input name="User_email" type="email" className="input" value={formData.User_email} onChange={handleChange} />
                {errors.User_email && <span style={{ color: "red" }}>{errors.User_email}</span>}
              </div>
            </td>
          </tr>
          <tr>
            <td><label>{intl.formatMessage({ id: age.label?.id, defaultMessage: age.label?.defaultMessage })}</label></td>
            <td>
              <div className={classnames(styles.requiredField, "is-secondary is-fullwidth mb-4")}>
                <select style={{ width: "100%" }} name="Age" className="input" value={formData.Age} onChange={handleChange}>
                  <option value="Not Chosen">-請選擇-</option>
                  <option value="15-20">15 - 20</option>
                  <option value="21-25">21 - 25</option>
                  <option value="26-30">26 - 30</option>
                  <option value="31-35">31 - 35</option>
                  <option value="36-45">36 - 45</option>
                  <option value="46+">46+</option>
                </select>
                {errors.Age && <span style={{ color: "red" }}>{errors.Age}</span>}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label>{intl.formatMessage({ id: centre.label?.id, defaultMessage: centre.label?.defaultMessage })}</label>
            </td>
            <td>
              <div className={classnames(styles.requiredField, "is-secondary is-fullwidth mb-4")}>
                <select style={{ width: "100%" }} name="Area" className="input" value={formData.Area} onChange={handleChange}>
                  <option value="Not Chosen">-請選擇-</option>
                  <option value="HK1">銅鑼灣</option>
                  <option value="HK4">觀塘</option>
                  <option value="HK7">沙田</option>
                  <option value="HK8">元朗</option>
                  <option value="HK2">佐敦</option>
                  <option value="HK9">將軍澳</option>
                  <option value="HK3">荃灣</option>
                </select>
                {errors.Area && <span style={{ color: "red" }}>{errors.Area}</span>}
              </div>
            </td>
          </tr>
          <tr>
            <td align="center" colSpan={2}>
              <div className={classnames(styles.requiredField, "is-secondary is-fullwidth mb-4")}>
                <input name="privacy" type="checkbox" value="privacy" checked={formData.privacy} onChange={handleChange} />
                <span className="ml-2">
                  {intl.formatMessage({ id: checkBox.label?.id, defaultMessage: checkBox?.label?.defaultMessage })}
                </span>
                {errors.privacy && <span style={{ color: "red" }}>{errors.privacy}</span>}
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }} colSpan={2}>
              <button className="is-primary button has-text-white has-text-weight-bold">
                <ArrowRight size={16} className={classnames(styles.buttonArrow, 'text-1 feather-align-right','mr-1')} />
                <span id='contact-form'>{intl.formatMessage(submit.placeholder)}</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
       
    </form>
  )
}

export default CustomForm;